<template>
  <div class="full-container">
    <title-header :title="'平板锁生产测试记录'"/>

    <div class="page-container record-container">
      <ul class="flex-column fields-box">
        <li class="fields-list">
          <p class="fields-item-label">时间区间：</p>
          <div class="flex-vertical fields-item">
<!--            <input type="text" class="fields-input" @click="showPopUp('bottom')">-->
            <div class="time-input start-time-input" @click="controlDate('start')">{{ startTime || ' ' }}</div>
            <div class="split">至</div>
            <div class="time-input end-time-input" @click="controlDate('end')">{{ endTime || ' ' }}</div>
          </div>
        </li>

        <li class="fields-list">
          <p class="fields-item-label">平板锁号：</p>
          <div class="flex-no-wrap fields-item">
            <input type="number" class="fields-input" v-model="lockName">
            <div class="bg fields-btn" @click="search">查询</div>
          </div>
        </li>
      </ul>

      <table cellspacing="0" cellpadding="0" class="test-table">
        <tr class="tr-header">
          <th>锁号</th>
          <th style="width: 40%">测试项</th>
          <th>测试时间</th>
          <th>状态</th>
        </tr>
        <tr class="tr-body" v-for="history in historyList" v-if="historyList.length > 0">
          <td>{{history.lockNo}}</td>
          <td>
            <div class="config-item">
              <div class="config-item-box" v-for="(config, index) in history.data" :key="'config-status-' + index">
                <div class="flex-vertical config-list-list">
                  <md-icon name="right" size="sm" v-if="config.status === 0"></md-icon>
                  <md-icon name="wrong" size="sm" v-if="config.status === 1"></md-icon>
                  <span style="word-break: keep-all;font-size: 0.3rem;">{{translateLabel(config.dataType)}}</span>
                  <span style="color: #13c2c2;margin-left: 8px;font-size: 0.2rem;"> {{config.desc}}</span>
                </div>
              </div>
            </div>
          </td>
          <td>{{history.createTime}}</td>
          <td>
            <md-icon name="right" size="lg" v-if="history.state === 0"></md-icon>
            <md-icon name="wrong" size="lg" v-if="history.state === 1"></md-icon>
          </td>
        </tr>
        <tr v-if="historyList.length === 0">
          <td style="text-align: center" colspan="4">暂无数据</td>
        </tr>
      </table>

      <div class="total">当前 {{currentPage}} 页，共 {{total}} 条数据</div>

      <div class="flex pager-container">
        <div class="pager-btn preview" @click="previewPage">上一页</div>
        <div class="pager-btn next" @click="nextPage">下一页</div>
      </div>


    </div>


    <div class="date-picker-box-modal" v-if="visible">
      <div class="modal-box">
        <div class="flex modal-action-box">
          <div class="modal-action-btn cancel-btn" @click="controlModal(false)">取消</div>
          <div class="modal-action-tips">请选择时间</div>
          <div class="modal-action-btn certain-btn" @click="containDate">确认</div>
        </div>
        <md-date-picker
            ref="datePicker"
            type="datetime"
            :default-date="currentDate"
            is-view
            keep-index
        ></md-date-picker>
      </div>

    </div>

    </div>


</template>

<script>

import TitleHeader from "../../components/TitleHeader";
import axios from "axios";
import ApiConfig from "../../assets/config/api.config";
import {Toast} from "mand-mobile";
import Api from "../../assets/config/api.config";
export default {
  name: "record",
  components: {
    TitleHeader
  },
  data() {
    return {
      visible: false,
      currentDate: new Date(),
      selectDate: '',
      selectItem: '',
      startTime: '',
      endTime: '',
      lockName: '', // 锁号查询
      currentPage: 1, // 当前页码数
      pageNumber: 0, // 页码总数
      historyList: [],
      total: 0,  // 总条数
      configList: []
    }
  },
  created() {
    this.getConfigList()
    this.getRecordList();
  },
  methods: {
    // 获取查询记录
    getRecordList() {
      let params = {
        startTime: this.startTime,
        endTime: this.endTime,
        sn: this.lockName,
        curPage: this.currentPage
      }
      axios.get(ApiConfig.queryList, {params: params}).then(res => {
        if(res.data.code) {
          this.historyList = res.data.data.list;
          this.total = res.data.data.Total;
          this.pageNumber = Math.ceil(this.total / res.data.data.pageSize);
        }

      }).catch(err => {
        if(err.response.status === 401) {
          Toast.info("请重新登录");
          this.$router.push({path: '/login'});
        }
      })
    },
    controlDate(item) {
      this.selectDate = '';
      this.selectItem = item;
      this.controlModal(true);
    },
    controlModal(bool) {
      this.visible = bool;
    },
    containDate() {
      this.selectDate = this.$refs.datePicker.getFormatDate();
      if(this.selectItem === 'start') {
        this.startTime = this.selectDate;
      }else {
        this.endTime = this.selectDate;
      }
      this.visible = false;
    },
    // 接口获取配置项信息
    getConfigList() {
      axios.post(Api.configList, {}).then(res => {
        if(res.data.code === 200) {
          this.configList = res.data.data.items;
        }
      }).catch(err => {
        if(err.response.status === 401) {
          Toast.info("请重新登录");
          this.$router.push({path: '/login'});
        }
      })
    },
    // 转化为汉字
    translateLabel(value, list = [], key='name') {
      let str = ''
      this.configList.map(l => {
        if(l.systemId === value) {
          str = l.name;
        }
      })
      return str;
    },
    // 上一页
    previewPage() {
      if(this.currentPage > 1) {
        this.currentPage--;
        this.getRecordList()
      }else {
        Toast.info('已经是第一页了')
      }
    },
    nextPage() {
      if(this.currentPage < this.pageNumber) {
        this.currentPage++;
        this.getRecordList();
      }else {
        Toast.info('已经是最后一页了');
      }
    },
    search() {
      this.currentPage = 1;
      this.getRecordList();
    }
  },
}
</script>

<style lang="less" src="./record.less" scoped></style>
